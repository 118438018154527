import FeatureGates from '@atlaskit/feature-gate-js-client';

import { fg } from '@confluence/feature-gating';

// only to be used in query preload situations
// in the case the we need an LD flag, we can adjust this accordingly
export const getIsFolderEnabled = () =>
	fg('confluence_cloud_folders') ||
	FeatureGates.getExperimentValue<'test' | 'control'>(
		'confluence_cloud_folders_experiment',
		'cohort',
		'control',
	) === 'test';

export const useIsFolderEnabled = (): {
	isFolderEnabled: boolean;
} => {
	const isFolderEnabled = getIsFolderEnabled();
	return { isFolderEnabled };
};
