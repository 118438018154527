import type { FC } from 'react';
import React, { useState, useCallback, useContext } from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Header, NavigationHeader } from '@atlaskit/side-navigation';
import { SpotlightManager as AkSpotlightManager } from '@atlaskit/onboarding';
import EditIcon from '@atlaskit/icon/glyph/edit';
import { N100A, N30, N500, B50, N20 } from '@atlaskit/theme/colors';
import { usePageLayoutResize } from '@atlaskit/page-layout';
import { Flex } from '@atlaskit/primitives';

import { useRouteName } from '@confluence/route-manager';
import {
	EDIT_SPACE_LOGO_DETAILS_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { SPACE_OVERVIEW, SPACE_INDEX } from '@confluence/named-routes';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';
import { EditSpaceLogo } from '@confluence/edit-space-logo';
import { SPAViewContext } from '@confluence/spa-view-context';
import { ExternalCollaboratorInSpaceNotify } from '@confluence/space-guest-list';
import {
	ExternalCollaboratorsSpaceInformationMessage,
	ExternalCollaboratorsSpaceInformationMessageType,
} from '@confluence/space-guest-list/entry-points/ExternalCollaboratorsSpaceInformationMessage';
import { getLegacyMessageQueue } from '@confluence/legacy-message-queue';
import { LoadableLazy } from '@confluence/loadable';
import { GuestOnboardingModalLoadable as GuestOnboardingModal } from '@confluence/external-collab-ui';

import type { SpaceNavigationQuery_space } from './__types__/SpaceNavigationQuery';
import { SpaceMoreActionsMenu } from './SpaceMoreActionsMenu';

const SpaceHeaderExCoOnboarding = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SpaceHeaderExCoOnboarding" */ './SpaceHeaderExCoOnboarding'
			)
		).SpaceHeaderExCoOnboarding,
});

const ANALYTICS_SOURCE = 'spaceHeader';
const ENTER_KEY_CODE = 13;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceIcon = styled.img({
	width: '24px',
	height: '24px',
	borderRadius: '5px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceIconContainer = styled.button({
	position: 'relative',
	width: '100%',
	cursor: 'pointer',
	padding: 0,
	border: 'none',
	background: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'.edit-icon-container': {
		position: 'absolute',
		left: 0,
		height: '24px',
		width: '24px',
		opacity: 0,
		transition: '0.5s ease',
		background: token('color.background.neutral', N100A),
		borderRadius: '5px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:focus .edit-icon-container': {
		opacity: 1,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderContainer = styled.div({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginTop: '-9px',
	position: 'relative',
	height: '56px',
});

const isOnSpaceRouteArgs = {
	selector: (routeName: string | undefined) =>
		routeName === SPACE_INDEX.name || routeName === SPACE_OVERVIEW.name,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceHeaderOverviewShortcut = styled.a<{
	isSelected: boolean;
}>({
	display: 'block',
	height: '100%',
	width: '100%',
	position: 'absolute',
	top: 0,
	left: 0,
	'&:hover': {
		textDecoration: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover + div, &:focus + div': {
		backgroundColor: token('color.background.neutral.subtle.hovered', N30),
		cursor: 'pointer',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:hover + div .edit-icon-container, &:focus + div .edit-icon-container': {
		opacity: 1,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: `${({ isSelected }) =>
		isSelected ? token('color.background.selected', B50) : 'transparent'}`,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-testid='space-icon-container'] [role='presentation']": {
		height: '24px',
		width: '24px',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[data-testid='space-header']": {
		paddingLeft: token('space.100', '8px'),
		paddingRight: token('space.100', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		span: {
			columnGap: token('space.100', '8px'),
		},
		'&:hover': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			backgroundColor: (p) =>
				p.isSelected
					? token('color.background.selected.hovered', '#cce0ff')
					: token('color.background.neutral.subtle.hovered', N20),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		h2: {
			letterSpacing: '0px',
			lineHeight: '16px',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
			'&, &:visited, &:active': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				color: (p) =>
					p.isSelected ? token('color.text.selected', '#0c66e4') : token('color.text', N500),
			},
		},
	},
});

const cssFn = () => ({
	height: '100%',
	width: '100%',
	'div&:hover': {
		backgroundColor: token('color.background.neutral', '#F4F5F7'),
		cursor: 'pointer',
		'span.edit-icon-container': {
			opacity: 1,
		},
	},
});

// match entire string to the slightly bolded style of the "External Collaborators" button
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OpenToTextContainer = styled.span({
	fontWeight: 500,
});

const i18n = defineMessages({
	editSpaceDetailsTooltip: {
		id: 'side-navigation.space-navigation.header.space-avatar-edit-header-tooltip',
		defaultMessage: 'Edit space details',
		description: 'Text for a tooltip showcasing where to click to edit space details',
	},
	editSpaceDetailsError: {
		id: 'side-navigation.space-navigation.header.space-avatar-error',
		defaultMessage: 'Something went wrong. Please try again.',
		description: 'Text for a flag notifying user there was an error with editing Space Details',
	},
	spacesOverviewShortcutTooltip: {
		id: 'side-navigation.space-overview-shortcut.tooltip',
		defaultMessage: 'View space overview',
		description: 'Tooltip when hovering over space overview shortcut in sidebar',
	},
});

type SpaceHeaderProps = WithFlagsProps & {
	spaceId: string;
	spaceKey: string;
	spaceName: string;
	space?: SpaceNavigationQuery_space;
	iconPath: string | undefined;
	homepageId?: string;
	containsExternalCollaborators: boolean;
	isUserExternalCollaborator: boolean;
};

// may or may not be wrapped by exco changeboarding/notification components
const SpaceAvatarHeader = ({
	spaceId,
	spaceKey,
	spaceName,
	spaceDescription,
	spaceOverviewUrl,
	iconPath,
	isStarred,
	isWatched,
	isSpaceAdmin,
	isSelected,
	sendOverviewShortcutAnalytics = () => {},
}) => {
	return (
		<HeaderContainer>
			<Tooltip
				position="top"
				content={<FormattedMessage {...i18n.spacesOverviewShortcutTooltip} />}
			>
				{(tooltipProps) => (
					<SpaceHeaderOverviewShortcut
						href={spaceOverviewUrl}
						isSelected={isSelected}
						aria-current={isSelected ? 'page' : undefined}
						{...tooltipProps}
						data-testid="space-header-overview-shortcut"
						onClick={sendOverviewShortcutAnalytics}
					>
						<Header
							iconBefore={
								<SpaceIcon
									src={iconPath || ''}
									data-testid="space-icon"
									alt=""
									data-vc="space-header-icon-image"
								/>
							}
							description={spaceDescription}
							testId="space-header"
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
							data-vc="space-icon"
						>
							<Flex alignItems="center" justifyContent="space-between">
								{spaceName}
								<SpaceMoreActionsMenu
									spaceId={spaceId}
									spaceKey={spaceKey}
									isStarred={isStarred}
									isWatched={isWatched}
									isSpaceAdmin={isSpaceAdmin}
								/>
							</Flex>
						</Header>
					</SpaceHeaderOverviewShortcut>
				)}
			</Tooltip>
		</HeaderContainer>
	);
};

const SpaceAvatarEditHeader = ({
	space,
	isOpen,
	onOpen,
	onClose,
	flags,
	intl,
	iconPath,
	leftSidebarState,
	expandLeftSidebar,
	spaceName,
	spaceKey,
	spaceId,
	spaceDescription,
	spaceOverviewUrl,
	isStarred,
	isWatched,
	isSelected,
	sendOverviewShortcutAnalytics = () => {},
	sendEditSpaceDetailsClickedAnalyticsEvent = () => {},
}) => {
	const experienceTracker = useContext(ExperienceTrackerContext);
	const [shouldRenderEditSpaceLogoDialog, setShouldRenderEditSpaceLogoDialog] = useState(false);

	const renderEditSpaceLogoDialog = useCallback(() => {
		setShouldRenderEditSpaceLogoDialog(true);
	}, []);

	const HandleSpaceIconClick = useCallback(
		(e: any) => {
			e.stopPropagation();
			e.preventDefault();
			experienceTracker.start({
				name: EDIT_SPACE_LOGO_DETAILS_LOAD_EXPERIENCE,
				// should not take more than 10s for the Edit Space Component Modal to appear
				timeout: 10000,
			});
			if (leftSidebarState.isFlyoutOpen) {
				expandLeftSidebar();
			}
			sendEditSpaceDetailsClickedAnalyticsEvent();
			void getLegacyMessageQueue()
				.then((legacyMessageQueue) => {
					legacyMessageQueue.push({
						type: 'ATLASSIAN_CONFLUENCE_EDIT_SPACE_LOGO',
						payload: {},
					});
				})
				.catch((error) => {
					void flags.showErrorFlag({
						id: 'edit-space-logo.actions.save.success',
						title: intl.formatMessage(i18n.editSpaceDetailsError),
					});

					experienceTracker.stopOnError({
						name: EDIT_SPACE_LOGO_DETAILS_LOAD_EXPERIENCE,
						error,
					});
				});
		},
		[
			flags,
			intl,
			experienceTracker,
			leftSidebarState.isFlyoutOpen,
			expandLeftSidebar,
			sendEditSpaceDetailsClickedAnalyticsEvent,
		],
	);

	const handleSpaceIconKeyDown = useCallback(
		(e: any) => {
			if (e.keyCode == ENTER_KEY_CODE) {
				HandleSpaceIconClick(e);
			}
		},
		[HandleSpaceIconClick],
	);

	if (!space) {
		return (
			<SpaceAvatarHeader
				spaceId={spaceId}
				spaceKey={spaceKey}
				isSpaceAdmin={false}
				isStarred={isStarred}
				isWatched={isWatched}
				iconPath={iconPath}
				spaceDescription={spaceDescription}
				spaceName={spaceName}
				spaceOverviewUrl={spaceOverviewUrl}
				isSelected={isSelected}
				sendOverviewShortcutAnalytics={sendOverviewShortcutAnalytics}
			/>
		);
	}

	const { id, key, type, operations = [] } = space;
	const isSpaceAdmin =
		!!operations.filter((op) => op.targetType === 'space' && op.operation === 'administer')
			.length || false;
	const isPersonalSpace = type === 'personal';

	if (!isSpaceAdmin || isPersonalSpace) {
		return (
			<SpaceAvatarHeader
				spaceId={id}
				spaceKey={key}
				isStarred={isStarred}
				isWatched={isWatched}
				isSpaceAdmin={isSpaceAdmin}
				iconPath={iconPath}
				spaceDescription={spaceDescription}
				spaceName={spaceName}
				spaceOverviewUrl={spaceOverviewUrl}
				isSelected={isSelected}
				sendOverviewShortcutAnalytics={sendOverviewShortcutAnalytics}
			/>
		);
	}

	return (
		<HeaderContainer>
			<Tooltip
				position="top"
				content={<FormattedMessage {...i18n.spacesOverviewShortcutTooltip} />}
			>
				{(tooltipProps) => (
					<SpaceHeaderOverviewShortcut
						href={spaceOverviewUrl}
						isSelected={isSelected}
						{...tooltipProps}
						data-testid="space-header-overview-shortcut"
						onClick={sendOverviewShortcutAnalytics}
					>
						<Header
							iconBefore={
								<SpaceIconContainer
									onClick={HandleSpaceIconClick}
									onKeyDown={handleSpaceIconKeyDown}
									onFocus={renderEditSpaceLogoDialog}
									onMouseEnter={renderEditSpaceLogoDialog}
									data-testid="space-icon-container"
									aria-label={intl.formatMessage(i18n.editSpaceDetailsTooltip)}
								>
									<Tooltip content={<FormattedMessage {...i18n.editSpaceDetailsTooltip} />}>
										<SpaceIcon
											src={iconPath || ''}
											data-testid="space-icon"
											data-vc="space-header-icon-image"
										/>
										{/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766 */}
										<span className="edit-icon-container">
											<EditIcon label="" primaryColor={token('color.icon.inverse', 'white')} />
										</span>
									</Tooltip>
								</SpaceIconContainer>
							}
							description={spaceDescription}
							testId="space-header"
							// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis
							cssFn={cssFn}
						>
							<Flex alignItems="center" justifyContent="space-between">
								{spaceName}
								<SpaceMoreActionsMenu
									spaceId={spaceId}
									spaceKey={spaceKey}
									isStarred={isStarred}
									isWatched={isWatched}
									isSpaceAdmin={isSpaceAdmin}
								/>
							</Flex>
						</Header>
					</SpaceHeaderOverviewShortcut>
				)}
			</Tooltip>
			{shouldRenderEditSpaceLogoDialog && (
				<EditSpaceLogo spaceKey={spaceKey} isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
			)}
		</HeaderContainer>
	);
};

const SpaceHeaderComponent: FC<SpaceHeaderProps> = ({
	spaceId,
	spaceKey,
	spaceName,
	space,
	iconPath,
	homepageId,
	containsExternalCollaborators,
	isUserExternalCollaborator,
	flags,
}: SpaceHeaderProps) => {
	const intl = useIntl();
	// Changeboarding for how to find a space's list of external collaborators by invoking a link from the space header:
	// Step 1: show an onboarding modal to display a space's list of external collaborators
	// Step 2: dismissing the modal stops the modal from being shown again in the future (shows only once per user per Confluence site)
	// Step 3: show a nonintrusive nudge "pulse" around the space header (when conditions are right)
	// Step 4: when user hovers over the pulse, show the full spotlight complete with overlay and message
	// Step 5: clicking on the spotlight action dismisses the entire changeboarding
	// This will only work if messages are shown in the same session. We'll need to find another implmentation if user has seen message #1 (modal) already, but not message #2 (pulse)
	const [showExCoOnboardingNudge, setShowExCoOnboardingNudge] = useState<boolean>(false);

	const [onboardingStep, setOnboardingStep] = useState<number>(0);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isEditSpaceModalOpen, setIsEditSpaceModalOpen] = useState(false);
	const onEditSpaceModalOpen = useCallback(() => setIsEditSpaceModalOpen(true), []);
	const onEditSpaceModalClose = useCallback(() => setIsEditSpaceModalOpen(false), []);

	const { isLeftSidebarCollapsed, leftSidebarState, expandLeftSidebar } = usePageLayoutResize();
	const { isNewUser } = useContext(SPAViewContext);

	// isSelected is used to highlight space header when on space overview
	const isSelected = useRouteName(isOnSpaceRouteArgs);

	const spaceOverviewUrl = SPACE_OVERVIEW.toUrl(
		{ spaceKey },
		{
			query: homepageId ? { [SPACE_OVERVIEW.HOMEPAGE_ID_QUERY_KEY]: homepageId } : undefined,
		},
	);

	const showExCoMessaging = !isUserExternalCollaborator && containsExternalCollaborators;

	const sendEditSpaceDetailsClickedAnalyticsEvent = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'editSpaceDetails',
				source: 'sideNavSpaceHeader',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const sendOverviewShortcutAnalytics = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'spaceHeader',
				source: 'sideNavigation',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const getSpaceDescription = () => {
		// ExCo spaceDescription takes precedence over the personal space one
		if (showExCoMessaging) {
			return (
				<OpenToTextContainer data-testid="space-exco-list-description-link">
					<ExternalCollaboratorsSpaceInformationMessage
						analyticsSource={ANALYTICS_SOURCE}
						messageType={ExternalCollaboratorsSpaceInformationMessageType.IN_SPACE}
						shouldShowMessageIcon={false}
						shouldShowAdditionalMessageContext={false}
						spaceKey={spaceKey}
						isSubtle
					/>
				</OpenToTextContainer>
			);
		} else {
			return undefined;
		}
	};

	const header = space ? (
		<SpaceAvatarEditHeader
			isOpen={isEditSpaceModalOpen}
			onOpen={onEditSpaceModalOpen}
			onClose={onEditSpaceModalClose}
			leftSidebarState={leftSidebarState}
			expandLeftSidebar={expandLeftSidebar}
			intl={intl}
			flags={flags}
			iconPath={iconPath}
			spaceDescription={getSpaceDescription()}
			space={space}
			spaceName={spaceName}
			spaceId={spaceId}
			spaceKey={spaceKey}
			spaceOverviewUrl={spaceOverviewUrl}
			isStarred={Boolean(space?.currentUser?.isFavourited)}
			isWatched={Boolean(space?.currentUser?.isWatched)}
			isSelected={isSelected}
			sendOverviewShortcutAnalytics={sendOverviewShortcutAnalytics}
			sendEditSpaceDetailsClickedAnalyticsEvent={sendEditSpaceDetailsClickedAnalyticsEvent}
		/>
	) : (
		<SpaceAvatarHeader
			spaceId={spaceId}
			spaceKey={spaceKey}
			isStarred={false}
			isWatched={false}
			isSpaceAdmin={false}
			iconPath={iconPath}
			spaceDescription={getSpaceDescription()}
			spaceName={spaceName}
			spaceOverviewUrl={spaceOverviewUrl}
			isSelected={isSelected}
			sendOverviewShortcutAnalytics={sendOverviewShortcutAnalytics}
		/>
	);

	// Suppress exco onboarding if the user is new, since they will be bombarded with other unrelated onboarding
	// messages which we don't want to overlap with this one. (See: CONFDEV-75463) We are seeking a more permanent
	// solution to onboarding overlapping with EP changeboarding messages, but for now adding the !isNewUser check
	// will prevent that behavior.
	const showExCoModalOnboarding = !isNewUser && showExCoMessaging;

	const showExCoSpotlightOnboarding =
		!isNewUser && showExCoMessaging && showExCoOnboardingNudge && !isLeftSidebarCollapsed;

	const onGuestListOnboardingModalShown = () => {
		setShowExCoOnboardingNudge(true);
		setOnboardingStep(1);
	};
	return (
		<AkSpotlightManager>
			<NavigationHeader>
				<GuestOnboardingModal />
				<>
					{showExCoModalOnboarding && onboardingStep === 0 && (
						<ExternalCollaboratorInSpaceNotify
							onModalClose={onGuestListOnboardingModalShown}
							spaceKey={spaceKey}
						/>
					)}
				</>
				{showExCoSpotlightOnboarding && onboardingStep === 1 ? (
					<SpaceHeaderExCoOnboarding
						header={header}
						onClose={() => setShowExCoOnboardingNudge(false)}
					/>
				) : (
					header
				)}
			</NavigationHeader>
		</AkSpotlightManager>
	);
};

export const SpaceHeader = withFlags(SpaceHeaderComponent);
