import type { FC } from 'react';
import React, { useState, useCallback } from 'react';
import type { ApolloError } from 'apollo-client';
import { defineMessages, useIntl } from 'react-intl-next';

import Spinner from '@atlaskit/spinner/spinner';

import { PageTree } from '@confluence/page-tree';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { usePageSpaceKey, usePageContentId } from '@confluence/page-context';
import { Attribution, ErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import { useHoverPageCard } from '@confluence/page-card';
import { useIsSuperAdmin } from '@confluence/super-admin';
import { LoadableAfterPaint } from '@confluence/loadable';
import { SpacePersistentCollapsibleSectionLoader } from '@confluence/collapsible-section/entry-points/SpacePersistentCollapsibleSectionLoader';
import { SpaceHoverPageCard } from '@confluence/page-card/entry-points/SpaceHoverPageCard';
import { useIsFolderEnabled } from '@confluence/folder-utils/entry-points/useIsFolderEnabled';

import { useSpaceViewsOptions, SpaceViewsSort } from './useSpaceViewsOptions';
import { FlexCenter } from './PresentationalComponents';
import { useSpaceViewsExperienceTracking } from './useSpaceViewsExperienceTracking';

const SpaceViews = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SpaceViews" */ './SpaceViews')).SpaceViews,
});

const PagesItemActions = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-PagesItemActions" */ './PagesItemActions'))
			.PagesItemActions,
});

const ContentTreeSearchLoader = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-ContentTreeSearch" */ './ContentTreeSearch'))
			.ContentTreeSearch,
});

const i18n = defineMessages({
	contentTitle: {
		id: 'space-views.collapsible-section.title',
		defaultMessage: 'Content',
		description: 'Header for all content section',
	},
	contentTooltip: {
		id: 'space-views.collapsible-section.title.tooltip',
		defaultMessage: 'Organize content in this space',
		description: 'Tooltip when hovering over content space sidebar collapsible section',
	},
});

type SpaceViewsControllerProps = {
	onDragDropSuccess?: (contentId: any) => void;
	isPeekingFromBlogs?: boolean;
	spaceHasContent?: boolean;
	homepageId?: string;
	onContentTreeLoadComplete?: () => void;
};

const SpaceViewsControllerComponent: FC<SpaceViewsControllerProps> = ({
	onDragDropSuccess,
	isPeekingFromBlogs,
	homepageId,
	onContentTreeLoadComplete,
}) => {
	const { formatMessage } = useIntl();
	const { isSuperAdmin } = useIsSuperAdmin();
	const [spaceKey] = usePageSpaceKey();
	const [contentId] = usePageContentId();
	const { isLicensed } = useSessionData();
	const {
		spaceViewsSort,
		setSpaceViewsSort,
		queryLoading: persistenceLoading,
		queryError: persistenceError,
		mutationLoading: persistenceUpdateLoading,
		mutationError: persistenceUpdateError,
	} = useSpaceViewsOptions(
		// @ts-ignore FIXME: `spaceKey` can be `undefined` here, and needs proper handling
		spaceKey,
		isLicensed,
	);
	const contentTreeSearchEnabled = useBooleanFeatureFlag('confluence.frontend.search-in-tree');
	const { isFolderEnabled } = useIsFolderEnabled();

	const [isPageTreeLoading, setIsPageTreeLoading] = useState<boolean>(true);
	const [pageTreeError, setPageTreeError] = useState<ApolloError | undefined>();
	const [isSpaceViewsLoading, setIsSpaceViewsLoading] = useState<boolean>(true);
	const [contentTreeSize, setContentTreeSize] = useState<number>(0);
	const [spaceViewsError, setSpaceViewsError] = useState<ApolloError | undefined>();
	const isPageTree = spaceViewsSort === SpaceViewsSort.TREE;

	const loading = persistenceLoading || (isPageTree ? isPageTreeLoading : isSpaceViewsLoading);
	const error = pageTreeError || spaceViewsError;

	const { trackedSetSpaceViewsSort, makeTrackedFetchMore } = useSpaceViewsExperienceTracking({
		loading,
		error,
		persistenceError,
		persistenceUpdateLoading,
		persistenceUpdateError,
		spaceViewsSort,
		setSpaceViewsSort,
	});

	const {
		onEnterHoverTarget: showHoverPageCardOnEnterHoverTarget,
		closeAllHoverPageCards,
		startCloseHoverPageCardTimer,
		clearHoverPageCardTimers,
		showHoverPageCardForItem,
		isHoverPageCardOptedIn,
		onLeaveHoverTarget,
		onBlurHoverTarget,
	} = useHoverPageCard(spaceViewsSort);

	const [isUnreadPageHovered, setIsUnreadPageHovered] = useState<boolean | undefined>(false);

	const [shouldRenderAfterIcon, setShouldRenderAfterIcon] = useState(false);
	const onEnterHoverTarget = useCallback(
		({
			isUnread,
			itemID,
			isQuickActionsFocused,
			hoverPageCardTriggerRef,
			isContextualCreateFocused,
		}: {
			isUnread?: boolean;
			itemID: string;
			isQuickActionsFocused: boolean;
			hoverPageCardTriggerRef: React.RefObject<HTMLDivElement>;
			isContextualCreateFocused?: boolean;
		}) => {
			showHoverPageCardOnEnterHoverTarget({
				itemID,
				isQuickActionsFocused,
				hoverPageCardTriggerRef,
				isContextualCreateFocused,
			});
			setIsUnreadPageHovered(isUnread);
			setShouldRenderAfterIcon(true);
		},
		[showHoverPageCardOnEnterHoverTarget, setShouldRenderAfterIcon],
	);

	const hoverPageCardProps = {
		onEnterHoverTarget,
		closeAllHoverPageCards,
		isHoverPageCardOptedIn,
		onLeaveHoverTarget,
		onBlurHoverTarget,
	};

	const checkForSearchEnabled = (children) => {
		if (contentTreeSearchEnabled) {
			return (
				<ContentTreeSearchLoader contentTreeSize={contentTreeSize} {...hoverPageCardProps}>
					{children}
				</ContentTreeSearchLoader>
			);
		}
		return children;
	};

	const renderContentTree = () => {
		if (persistenceLoading) {
			return (
				<FlexCenter>
					<Spinner size="medium" />
				</FlexCenter>
			);
		}
		if (isPageTree) {
			return checkForSearchEnabled(
				<PageTree
					onDragDropSuccess={onDragDropSuccess}
					isPeekingFromBlogs={isPeekingFromBlogs}
					isSuperAdmin={isSuperAdmin}
					setIsPageTreeLoading={setIsPageTreeLoading}
					setPageTreeError={setPageTreeError}
					shouldRenderAfterIcon={shouldRenderAfterIcon}
					homepageId={homepageId}
					setContentTreeSize={setContentTreeSize}
					onContentTreeLoadComplete={onContentTreeLoadComplete}
					{...hoverPageCardProps}
				/>,
			);
		}
		return (
			<SpaceViews
				spaceViewsSort={spaceViewsSort}
				setIsSpaceViewsLoading={setIsSpaceViewsLoading}
				setSpaceViewsError={setSpaceViewsError}
				trackedSetSpaceViewsSort={trackedSetSpaceViewsSort}
				makeTrackedFetchMore={makeTrackedFetchMore}
				spaceKey={spaceKey}
				contentId={contentId}
				shouldRenderAfterIcon={shouldRenderAfterIcon}
				contentTreeSize={contentTreeSize}
				onContentTreeLoadComplete={onContentTreeLoadComplete}
				{...hoverPageCardProps}
			/>
		);
	};

	return (
		<SpacePersistentCollapsibleSectionLoader
			renderAfterIconButton={(setHeaderExpandedState) => (
				<PagesItemActions
					isLicensed={isLicensed}
					selection={spaceViewsSort}
					setSelection={trackedSetSpaceViewsSort}
					spaceKey={spaceKey || ''}
					contentTreeSize={contentTreeSize}
					// expand collapsible header after creating content
					// this is needed to show folder inline rename input after creation
					{...(isFolderEnabled && {
						onItemClick: () => setHeaderExpandedState?.(true),
					})}
				/>
			)}
			headerTitle={formatMessage(i18n.contentTitle)}
			sectionName="contentTree"
			spaceKey={spaceKey || ''}
			tooltipContent={formatMessage(i18n.contentTooltip)}
		>
			{renderContentTree()}
			{/* we place the popup here and use a portal to adjust the desired location.
        This way we don't need to render a popup per pageTreeItem
        The component should only rerender whenever showHoverPageCardForItem changes.
        */}
			{isHoverPageCardOptedIn && showHoverPageCardForItem.itemID ? (
				<SpaceHoverPageCard
					showHoverPageCardForItem={showHoverPageCardForItem}
					closeAllHoverPageCards={closeAllHoverPageCards}
					clearHoverPageCardTimers={clearHoverPageCardTimers}
					startCloseHoverPageCardTimer={startCloseHoverPageCardTimer}
					onBlurHoverTarget={onBlurHoverTarget}
					shouldUseAutomationDiscovery
					key={showHoverPageCardForItem.itemID}
					isUnread={isUnreadPageHovered}
				/>
			) : null}
			{persistenceError && <ErrorDisplay error={persistenceError} />}
		</SpacePersistentCollapsibleSectionLoader>
	);
};

export const SpaceViewsController: FC<SpaceViewsControllerProps> = (props) => (
	<ErrorBoundary attribution={Attribution.DISCO}>
		{SpaceViewsControllerComponent({ ...props })}
	</ErrorBoundary>
);
